import React from 'react'
import { graphql } from 'gatsby'
import { ASSETS } from '@helpers'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage } from 'gatsby-plugin-image'

import {
  FBIcon,
  Footer,
  IGIcon,
  Layout,
  MainTitle,
  Breadcrumb,
  MetaHeader,
  ShopeeIcon,
  VideoSlider,
  OrderNowButton,
  ModalWithSlider,
} from '@components'

const title = 'Sabun Champaca'
const subtitle = `sabun pelbagai guna`

const videos = [
  {
    type: 'youtube',
    src: 'https://www.youtube.com/embed/7e6kqEihmXU',
    title: 'BELI SABUN MENANG DUIT‼️',
    imgSrc: 'https://img.youtube.com/vi/7e6kqEihmXU/maxresdefault.jpg',
  },
  {
    type: 'youtube',
    src: 'https://www.youtube.com/embed/fGXY8tX9A28',
    title: 'Bagus sangat ke entah2 scam je servis dia ni?!',
    imgSrc: 'https://img.youtube.com/vi/fGXY8tX9A28/sddefault.jpg',
  },
  {
    type: 'youtube',
    src: 'https://www.youtube.com/embed/ILfuGCPHaQg',
    title: 'Apa kata mereka tentang servis dapurkayangan Sabun Chempaca Rose',
    imgSrc: 'https://img.youtube.com/vi/ILfuGCPHaQg/maxresdefault.jpg',
  },
  { src: ASSETS.mp4.promo1, controls: true },
  { src: ASSETS.mp4.promo2, controls: true },
  { src: ASSETS.mp4.promo3, controls: true },
  { src: ASSETS.mp4.promo4, controls: true },
  { src: ASSETS.mp4.terpesona, muted: true, loop: true },
  { src: ASSETS.mp4.sinkSumbat, controls: true },
  { src: ASSETS.mp4.sabun, controls: true },
  {
    src: ASSETS.mp4.demo_keberkesanan_sabun_champaca_1,
    controls: true,
  },
  {
    src: ASSETS.mp4.demo_keberkesanan_sabun_champaca_2,
    controls: true,
  },
  {
    src: ASSETS.mp4.demo_keberkesanan_sabun_champaca_3,
    controls: true,
  },
  {
    src: ASSETS.mp4.demo_keberkesanan_sabun_champaca_4,
    controls: true,
  },
  {
    src: ASSETS.mp4.demo_keberkesanan_sabun_champaca_5,
    controls: true,
  },
  {
    src: ASSETS.mp4.demo_keberkesanan_sabun_champaca_6,
    controls: true,
  },
  {
    src: ASSETS.mp4.demo_keberkesanan_sabun_champaca_7,
    controls: true,
  },
  {
    src: ASSETS.mp4.demo_keberkesanan_sabun_champaca_8,
    controls: true,
  },
]

export default function Sabun({ data }: any) {
  const {
    imgs: { nodes },
    mdx: {
      body: mainBody,
      frontmatter: {
        img: {
          name,
          childImageSharp: { gatsbyImageData },
        },
      },
    },
    site: {
      siteMetadata: {
        domain,
        social: {
          sabun: { shopee, ig, fb },
        },
      },
    },
  } = data

  const othersImg = nodes.filter((img: any) => /^other/i.test(img.name))
  const testimonies = nodes.filter((img: any) => /^testimoni/i.test(img.name))

  return (
    <Layout>
      <MetaHeader
        title={title}
        description={`${title} ${subtitle}`}
        imgUrl={`${domain}${gatsbyImageData.src}`}
      />
      <div className='min-h-screen flex items-center justify-center'>
        <div className='flex-1 max-w-4xl mx-auto p-2 md:p-10 space-y-10'>
          <Breadcrumb />
          <MainTitle title={title} />

          <GatsbyImage
            className='rounded-tl rounded-tr border'
            image={gatsbyImageData}
            alt={name}
            style={{
              height: '100%',
              width: '100%',
            }}
          />

          <div className={'bg-white rounded shadow-md p-10'}>
            <div className='prose prose-xl'>
              <MDXProvider components={{}}>
                <MDXRenderer>{mainBody}</MDXRenderer>
              </MDXProvider>
            </div>

            <div className='mt-10 flex items-center justify-center'>
              <OrderNowButton shopeeUrl={shopee}>
                Tempah Sekarang
              </OrderNowButton>
            </div>

            <div className='mt-10' />

            <ModalWithSlider
              nodes={othersImg}
              RenderItem={({ item }: any) => (
                <Boxes
                  item={item}
                  renameFn={(name: string) => name.replace(/^other/i, '')}
                />
              )}
            />
          </div>

          <div className='bg-white rounded shadow-md p-10'>
            <MainTitle title='Koleksi testimoni' />

            <ModalWithSlider
              nodes={testimonies}
              RenderItem={({ item }: any) => (
                <Boxes
                  item={item}
                  renameFn={(name: string) => name.replace(/^testimoni/i, '')}
                />
              )}
            />
          </div>

          <div className='bg-white rounded shadow-md'>
            <MainTitle title='Koleksi Video' />
            <VideoSlider videos={videos} />
          </div>
        </div>
      </div>

      <Footer>
        <li>
          <IGIcon ig={ig} />
        </li>
        <li>
          <FBIcon fb={fb} />
        </li>
        <li>
          <ShopeeIcon shopee={shopee} />
        </li>
      </Footer>
    </Layout>
  )
}

export const Boxes = ({ item, renameFn }: any) => {
  const {
    name,
    childImageSharp: { gatsbyImageData },
  } = item

  const renameImg = renameFn(name).replace(/(_|-)/g, ' ')
  const aspectRatio = gatsbyImageData.width / gatsbyImageData.height

  const autoSpanGridBasedOnAspectRatio =
    aspectRatio > 1
      ? 'col-span-1 sm:col-span-2'
      : aspectRatio < 1
      ? 'row-span-1 sm:row-span-2'
      : ''

  return (
    <div
      className={`h-full bg-white rounded shadow-md hover:shadow-lg border ${autoSpanGridBasedOnAspectRatio}`}>
      <GatsbyImage
        className='rounded cursor-pointer h-full'
        image={gatsbyImageData}
        alt={renameImg}
      />
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        domain
        social {
          sabun {
            fb
            ig
            shopee
          }
        }
      }
    }
    mdx(frontmatter: { title: { eq: "Sabun Champaca" } }) {
      body
      frontmatter {
        img {
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    imgs: allFile(
      filter: {
        absolutePath: { regex: "/contents/sabun/" }
        extension: { ne: "mdx" }
      }
      sort: { fields: name }
    ) {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`
